import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { userApi } from '../../api';
import { IUserStore } from './store.types';

export const useUserStore = create<IUserStore>()(immer((set) => ({
  customerId: 4,
  user: undefined,
  async getCurrentUser() {
    const user = await userApi.getWhoAmI();

    set({ user });

    return user;
  }
})));
