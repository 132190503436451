import { useQuery } from '@tanstack/react-query';
import { useAppStore } from 'shared/lib';
import { EUserQueryKey, useUserStore } from '../../model';

export const useWhoami = () => {
  const getCurrentUser = useUserStore(state => state.getCurrentUser);
  const apiUrl = useAppStore(state => state.config.api);

  const userQuery = useQuery({
    queryKey: [
      EUserQueryKey.USER,
    ],
    enabled: !!apiUrl,
    queryFn: getCurrentUser,
    refetchOnWindowFocus: true,
    retry: false,
  });

  return {
    userQuery
  };
};
