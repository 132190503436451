import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { version } from '../package.json';
import App from './app/app.tsx';

import './app/lib/i18n';

console.log('App version', version);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
